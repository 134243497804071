// extracted by mini-css-extract-plugin
export var ArtistDescription = "KeithHaring-module--ArtistDescription--F9R1X";
export var ArtistInfos = "KeithHaring-module--ArtistInfos--bkXlb";
export var ButtonWrapper = "KeithHaring-module--ButtonWrapper --U4P1v";
export var CardWrapper = "KeithHaring-module--CardWrapper--ByTgj";
export var CarrouselWrapper2 = "KeithHaring-module--CarrouselWrapper2--sTy7X";
export var Citations = "KeithHaring-module--Citations--QKeii";
export var DescriptionWrapper = "KeithHaring-module--DescriptionWrapper--A9+t+";
export var ImageWrapper = "KeithHaring-module--ImageWrapper--Lmwwq";
export var LinkWrapper = "KeithHaring-module--LinkWrapper--laEWq";
export var MobileProtrait = "KeithHaring-module--MobileProtrait--cUq5k";
export var More = "KeithHaring-module--More--71DOi";
export var MoreButton = "KeithHaring-module--MoreButton--SH-Bm";
export var NameWrapper = "KeithHaring-module--NameWrapper--4l12H";
export var PdpWrapper = "KeithHaring-module--PdpWrapper--dzlQ6";
export var PhotosWrapper = "KeithHaring-module--PhotosWrapper--YyMN9";
export var ProfilWrapper = "KeithHaring-module--ProfilWrapper--ME-0E";
export var TitleWrapper = "KeithHaring-module--TitleWrapper--0U4yj";
export var Wrapper = "KeithHaring-module--Wrapper--ggeGR";